import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import Footer from "./Footer"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  return (
    <>
      <html lang="it">
        <Header />
        <main style={{ marginTop: "70px" }}>{children}</main>
        <CookieConsent
          location="bottom"
          flipButtons
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="No thanks"
          cookieName="gatsby-gdpr-google-analytics"
          style={{
            background: "#e3e4e6",
            minHeight: "5rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            color: "black",
          }}
          declineButtonStyle={{
            background: "transparent",
            color: "black",
            fontSize: "13px",
          }}
          buttonStyle={{ background: "#62ff4a", fontSize: "15px" }}
        >
          This site uses cookies to improve the user experience
        </CookieConsent>
        <Footer />
      </html>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
