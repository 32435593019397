import { Link } from "gatsby"
import React from "react"
import { Container, Navbar, Nav, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

const Header = () => (
  <div>
    <Helmet>
      <title>
        Italian Art Trust O.N.L.U.S. - Finanziamento di giovani artisti
      </title>
      <meta
        name="description"
        content="Italian Art Trust O.N.L.U.S. promoziona i giovani artisti under 35 con l’obiettivo di finanziare e valorizzare la produzione artistica in Italia, promuovendone l’esposizione al pubblico e la sua diffusione. In particolare, Italian Art Trust Onlus ha l’intento di sviluppare delle iniziative di utilità sociale nei settori della promozione dell’arte e della cultura, della tutela e valorizzazione del patrimonio culturale, storico-artistico al fine di arrecare benefici anche a persone svantaggiate in ragione di condizioni fisiche, psichiche, economiche, sociali o familiari, ai sensi dell’art."
      />
      <meta name="theme-color" content="#62ff4a" />
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />

      <script
        src="https://unpkg.com/react/umd/react.production.min.js"
        crossorigin
      ></script>
      <script
        src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
        crossorigin
      ></script>
    </Helmet>
    <header>
      <Container>
        <Row md={12}>
          <Link to="/" className="brand position-fixed">
            Italian Art Trust
          </Link>
        </Row>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="1"
                  className="nav-link"
                  to="#chisiamo"
                  activeClassName="active"
                >
                  Chi siamo
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="2"
                  className="nav-link"
                  to="#bando"
                  activeClassName="active"
                >
                  Bando
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="3"
                  className="nav-link"
                  to="#prima_ed"
                  activeClassName="active"
                >
                  1ª Edizione
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="4"
                  className="nav-link"
                  to="#crowdfunding"
                  activeClassName="active"
                >
                  Crowdfunding
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="5"
                  className="nav-link"
                  to="#partners"
                  activeClassName="active"
                >
                  Partner
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey="6"
                  className="nav-link"
                  to="#contatti"
                  activeClassName="active"
                >
                  Contatti
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  </div>
)

export default Header
