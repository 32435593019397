import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import bandoCompleto from "../files/Bando_IAT.pdf"
import IAT_oggettodelbando from "../files/OGGETTODELBANDO.pdf"
import IAT_requisitidipartecipazione from "../files/REQUISITIDIPARTECIPAZIONE.pdf"
import IAT_modalitapresentazionedomanda from "../files/MODALITAPRESENTAZIONEDOMANDA.pdf"
import IAT_domandapartecipazione from "../files/ALLEGATO31.pdf"
import IAT_propostaprogettuale from "../files/ALLEGATO32.pdf"
import IAT_dichiarazioneoriginalitaefattibilita from "../files/ALLEGATO33.pdf"
import IAT_regolamentoprivacy from "../files/ALLEGATO34.pdf"
import IAT_prospettoeconomico from "../files/ALLEGATO35.pdf"
import IAT_cronoprogramma from "../files/ALLEGATO36.pdf"
import BANDOFORBICIDORO from "../files/BANDOFORBICIDORO.pdf"

class Index extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout>
        <Container>
          <section className="section" id="home">
            <div className="section__wrap"></div>
            <Row>
              <Col md={12}>
                <Img
                  fluid={data.logoImage.childImageSharp.fluid}
                  alt="IAT__logo"
                  className="logo"
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 2, offset: 5 }} className="btn__container">
                <a href="/sostienici">
                  <button
                    className="IAT__button__home"
                    variant="success"
                    href="/sostienici"
                  >
                    SOSTIENICI
                  </button>{" "}
                </a>
              </Col>
            </Row>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="chisiamo">
            <div className="section__wrap">
              <h1 className="section__header">CHI SIAMO</h1>
              <Row>
                <Col md={12} className="about__text">
                  <p>
                    Italian Art Trust O.N.L.U.S. è un progetto no-profit volto
                    al finanziamento, sostegno e promozione di giovani artisti
                    under 35, che riveste la struttura giuridica di Trust
                    O.N.L.U.S.. Nasce con l’intento di sviluppare iniziative di
                    utilità sociale nei settori della promozione dell’arte e
                    della cultura, al fine di fornire supporto economico e
                    laboratoriale ai giovani artisti durante il loro percorso
                    accademico e post-accademico. L'obiettivo è quello di
                    finanziare e salvaguardare la produzione artistica
                    contemporanea in Italia, promuovendone l'esposizione al
                    pubblico.
                  </p>
                  <p className="about__stars"> * * * </p>
                  <p>
                    L’ottenimento della qualifica di O.N.L.U.S. consentirà alle
                    persone fisiche e giuridiche che vorranno, mediante
                    erogazioni liberali, sostenere le iniziative di Italian Art
                    Trust di usufruire di rilevanti agevolazioni fiscali
                    previste dalla normativa vigente. I progetti finanziati
                    saranno selezionati accuratamente da un Comitato scientifico
                    indipendente, mentre il ruolo di Trustee è affidato ad un
                    soggetto terzo e indipendente - L&P Trustee S.r.l. - che
                    garantisce una gestione efficace ed imparziale del Trust.
                  </p>
                </Col>
              </Row>
            </div>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="bando">
            <div className="section__wrap">
              <h1 className="section__header">BANDO - 2022</h1>
              <Row className="">
                <Col md={12}>
                  <p>
                    {" "}
                      Seguite la nostra <a href="https://www.instagram.com/italian_art_trust/" target="blank">pagina instagram</a> per ulteriori informazioni sulla prossima edizione di Italian Art Trust O.N.L.U.S.
                      </p>
                  <br></br>
                  <h4>BANDO ECCEZIONALE</h4>
                  <p>
                    Bando eccezionale Italian Art Trust O.N.L.U.S. - Sartoria Italiana
                    <br></br>
                    <a
                      className=""
                      href={BANDOFORBICIDORO}
                      download="Bando Forbici D'Oro"
                    >
                      Clicca qui
                          </a> per scaricare il Bando.
                  </p>
                </Col>
              </Row>
            </div>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="prima_ed">
            <div className="section__wrap">
              <h1 className="section__header">1ªEDIZIONE  -  2021</h1>
              <Row className="winner bando__parent">
                <Col md={12}>
                  <p>
                    Ringraziamo tutti coloro che ci hanno sostenuto e tutti gli artisti che hanno partecipato al progetto.
                  <br></br><br></br>
                  Siamo lieti di annunciare la vincitrice della prima edizione di Italian Art Trust Onlus. Il comitato tecnico ha deciso di assegnare il premio ad <br></br>ALICE RONCHI, con il suo progetto “Saetta (Totem)”.
                  <br></br><br></br>
                  L’opera andrà ora in produzione e verrà mostrata al pubblico nell’ambito dell’esposizione finale del premio a Milano.
                  Si ringrazia Banca Generali Private, Studio legale e tributario Loconte&Partners, Art Defender e il Comitato Tecnico per il supporto della prima edizione.
                </p>
                </Col>
              </Row>
              <h1 className="section__header">VINCITRICE</h1>
              <Row className="winner"></Row>
              <Row className="winner">
                <Col md={6}>
                  <h2 className="winner_header">
                    Alice Ronchi
                  </h2>
                  <p>
                    <br></br>
                  Alice Ronchi, classe 1989, è un'artista che vive e lavora a Milano. Si è laureata nel 2012 alla Nuova Accademia di Belle Arti di Milano e nel 2015 al Sandberg Instituut di Amsterdam.
                  Ha esposto in mostre collettive alla Triennale di Milano, a Palazzo Reale, al MAMbo di Bologna e al MAXXI di Roma. Tra le personali si annoverano Colazione sull'erba (2014) e Majestic Solitude (2018) nella galleria Francesca Minini, A Dinosaur Can Be Vain (2015) nella galleria Fons Welters di Amsterdam e diverse altre inclusi spazi di progetto e opere permanenti in luoghi pubblici. Dal 2020 insegna alla Nuova Accademia di Belle Arti (NABA) a Milano e collabora con istituzioni e scuole per i quali sviluppa workshop per bambini.<br></br><br></br>Nei suoi lavori oggetti d’uso quotidiano incontrano il mondo dell’invenzione e della fantasia. La ricerca della “meraviglia” è un tema costante nella sua ricerca, popolata di figure familiari e allo stesso tempo enigmatiche, prodotto di una propria rielaborazione astratta. A metà strada tra il ludico e il minimale, il suo lavoro è una sintesi tra il rigore della forma e la semplicità d’espressione. Con l’uso di differenti media, che variano dalla scultura alla fotografia, e dalla performance al video, Alice interroga la realtà attraverso uno sguardo attento e curioso, restituendoci una sua magica visione.

                  </p>
                </Col>
                <Col md={6}>
                  <Img
                    fluid={data.winner2021Image.childImageSharp.fluid}
                    alt="ALICE RONCHI"
                    className="img__pannel"
                  />
                </Col>
              </Row>
              <Row className="winner">
                <Col md={6}>
                  <Img
                    fluid={data.winner2021projectImage.childImageSharp.fluid}
                    alt="ALICE RONCHI"
                    className="img__pannel"
                  />
                </Col>
                <Col md={6}>
                  <h2 className="winner_header">
                    Saetta (Totem)
                  </h2>
                  <p>
                    Nella sua semplicità, nel simbolo della saetta, vi è una forte espressività ed è questo ciò che più apprezzo di questo elemento che proviene dal cielo. In essa ho intravisto più significati che, seppure di difficile definizione, mi sono sempre sembrati familiari e potenti.” Così ci ha spiegato Alice Ronchi, vincitrice del bando di Italian Art Trust Onlus con il suo progetto “Saetta (Totem)”.
                    <br></br><br></br>
                    “Saetta (Totem”) è una grande saetta di quasi due metri che assume le sembianze di un totem e che ad esso si ispira non solo per la sua fisionomia e sviluppo verticale ma anche e soprattutto per il suo significato e definizione. Un totem – in antropologia – è un'entità naturale o soprannaturale che ha un significato simbolico particolare per una persona e al quale ci si sente legati per tutta la vita.
                     <br></br><br></br>
                    Potente, elegante ed evocativa, la scultura si compone di cinque moduli ellittici svasati e montati uno sopra l’altro ed è caratterizzata da una forte semplicità sia di forme che di costruzione. Alice ha scelto di realizzare singolarmente ogni modulo e poi sormontarlo con una lamiera di metallo calandrato, saldato, trattato e verniciato di nero lucido (una scelta, per l’artista, non solo strutturale ma anche concettuale, sorella del pensiero che ha generato anche la sua serie “Flora”). Concepita a grandezza umana (180 cm), “Saetta (Totem)” si configura nello spazio come una presenza importante e un elemento misterioso al quale rivolgersi.
                  </p>
                </Col>
              </Row>
              <h1 className="section__header">GIURIA - 2021</h1>
              <Row className="pannel">
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.anselmiImage.childImageSharp.fluid}
                    alt="ROLANDO ANSELMI"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>ROLANDO ANSELMI</h2>
                      <p>
                        Rolando Anselmi apre la sua galleria a Berlino nel 2013,
                        con l'obiettivo di creare una piattaforma
                        internazionalmente aperta dove promuovere e
                        rappresentare artisti attraverso mostre, pubblicazioni,
                        fiere, progetti outdoor. Nel 2020 stabilisce un nuovo
                        headquarter nella città di Roma che riunisce spazi
                        espositivi, uffici, magazzini ed un progetto di
                        residenze artistiche.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <div className="img__pannel__wrap">
                    <Img
                      fluid={data.bergaminiImage.childImageSharp.fluid}
                      alt="MATTEO BERGAMINI"
                      className="img__pannel"
                    />
                  </div>
                  <div className="">
                    <div>
                      <h2>MATTEO BERGAMINI</h2>
                      <p>
                        Giornalista, critico d'arte e curatore. <br></br>È
                        Direttore Responsabile di exibart e collaboratore per il
                        settimanale D La Repubblica.<br></br>Con l'Associazione
                        ArtCityLab e Carlo Vanoni ha ideato e curato la prima
                        edizione di “BienNoLo”, Ex Spazio Cova, Milano, 2019.
                        Tra le altre mostre curate “Marcella Vanzo. To wake up
                        the living, to wake up the dead”, Fondazione Berengo,
                        Venezia, 2019; “Luca Gilli, Di-stanze”, Museo Diocesano,
                        Milano, 2018. Ha curato i volumi “Un Musée après” di
                        Luca Gilli, Vanilla Edizioni, 2018; “Francesca Alinovi”
                        (insieme a Veronica Santi), Postmedia Books, 2019.
                        Appassionato viaggiatore, è autore di “Prisa Mata.
                        Diario Marocchino”, Sartoria Editoriale, 2020.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.bonettiImage.childImageSharp.fluid}
                    alt="GERMANO BONETTI"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>GERMANO BONETTI</h2>
                      <p>
                        Appassionato collezionista, avvocato e supporter di
                        giovani artisti. La sua collezione nasce all’inizio
                        degli anni 2000, dall’esigenza di approfondire e dare
                        concretezza all’interesse per l’arte moderna e
                        contemporanea da sempre avuto. <br></br> I primi
                        acquisti sono iniziati nel 2005/2006, dapprima
                        approfondendo le avanguardie italiane ed europee degli
                        anni '70, per poi esplorare l’arte dei paesi emergenti
                        (Africa, Asia e Australia) dal 2012.<br></br>Da fine
                        2016 la collezione ha preso però una direzione diversa,
                        privilegiando il rapporto con artisti giovani (under
                        30), l’esplorazione delle nuove tecnologie e modalità
                        espressive. In particolare, a supporto dei giovani
                        artisti gestisce un profilo Instagram attraverso il
                        quale porta avanti un’attività di ricerca e contatto
                        finalizzata alla pubblicazione del loro lavoro.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pannel">
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.botreImage.childImageSharp.fluid}
                    alt="FRANZ BOTRÉ"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>FRANZ BOTRÉ</h2>
                      <p>
                        Franz Botré nasce a Milano e già a 14 anni comincia a
                        lavorare come tipografo, scoprendo la sua passione per
                        la comunicazione. Segue studi serali e diventa
                        stampatore, litografo, fotolitografo, grafico e poi, dal
                        1980, giornalista fino a diventare editore e direttore
                        della casa editrice Swan Group che fonda nel 2001: crea
                        tra le altre pubblicazioni di qualità, Monsieur, Spirito
                        diVino e Mestieri D’Arte. Nel 2015 nasce Arbiter, nel
                        2016 Kairós. Nel 2020 idea e realizza il primo Milano su
                        Misura con il Trofeo Arbiter, 48 ore dedicate
                        all’eleganza maschile per riconoscere il mondo del fatto
                        a mano, del su misura.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.farinottiImage.childImageSharp.fluid}
                    alt="ROSSELLA FARINOTTI"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>ROSSELLA FARINOTTI</h2>
                      <p>
                        Rossella Farinotti è critica d’arte contemporanea,
                        curatrice e giornalista. Scrive per riviste di settore
                        come “Flash Art Italia”, “Zero.eu”, “Telescope”,
                        “exibart”.<br></br>Direttrice esecutiva dell’archivio
                        Gio' Pomodoro; consulente curatoriale per Sergio Rossi
                        The Magic Kingdom e la piattaforma KOONES; curatore
                        della Cittadella degli Archivi di Milano e docente
                        presso l’Università Cattolica di Milano e Naba.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.lecciImage.childImageSharp.fluid}
                    alt="FRANCESCO LECCI"
                    className="img__pannel"
                  />
                  <h2>FRANCESCO LECCI</h2>
                  <p>
                    Fondatore e direttore di Clima, nata nel 2015 con
                    l'obiettivo di supportare giovani artisti italiani e
                    internazionali nello sviluppo della loro carriera.<br></br>
                    Con la sua galleria partecipa ad importanti fiere
                    internazionali tra cui Frieze NY, Artissima a Torino, Arco
                    Madrid e Material Art Fair in Messico.
                  </p>
                </Col>
              </Row>
              <Row className="pannel">
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.montiImage.childImageSharp.fluid}
                    alt="EDOARDO MONTI"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>EDOARDO MONTI</h2>

                      <p>
                        Collezionista e curatore. <br></br>È fondatore e
                        direttore di Palazzo Monti, un centro culturale che
                        ospita un programma di residenza, la sua collezione
                        privata di arte contemporanea e uno spazio espositivo,
                        dove ha curato 23 mostre in tre anni.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <div className="img__pannel__wrap">
                    <Img
                      fluid={data.poggialiImage.childImageSharp.fluid}
                      alt="MARCO POGGIALI"
                      className="img__pannel"
                    />
                  </div>
                  <div className="">
                    <div>
                      <h2>MARCO POGGIALI</h2>
                      <p>
                        Da sempre inserito nelle attività culturali e
                        imprenditoriali di famiglia, cresce in contatto con
                        artisti come Enzo Cucchi, Gilberto Zorio e Luigi Ontani,
                        nel 2016 fonda insieme al fratello Lorenzo, Galleria
                        Poggiali.<br></br>Insieme sviluppano progetti con
                        artisti quali Eliseo Mattiacci, Claudio Parmiggiani e
                        Grazia Toderi. Nel gennaio 2018 inaugura la sede
                        milanese, volta a promuovere una ricerca artistica di
                        stampo internazionale legata a tematiche contingenti al
                        contesto culturale contemporaneo. Fra le mostre da lui
                        dirette si ricordano: Kennedy Yanko “Because it’s in my
                        Blood”, (2020); Goldschmied & Chiari “Paesaggi
                        Artificiali”, Thomas Kovachevich “Portrait Of A Room”,
                        (2019); Manfredi Beninati “Se Questo È Un Sabato”,
                        (2018); John Isa-acs “Archipelago”, (2018).
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}>
                  <div className="img__pannel__wrap">
                    <Img
                      fluid={data.raimondiImage.childImageSharp.fluid}
                      alt="STEFANO RAIMONDI"
                      className="img__pannel"
                    />
                  </div>
                  <div className="">
                    <div>
                      <h2>STEFANO RAIMONDI</h2>
                      <p>
                        Stefano Raimondi (1981) è critico e curatore d'arte.
                        <br></br>Dal 2020 è direttore artistico di ArtVerona e
                        dal 2010 è direttore del network culturale The Blank
                        Contemporary Art. È stato curatore alla GAMeC Bergamo
                        dal 2011 al 2017. Nel 2011 ha co-ideato BACO. Ha curato
                        le mostre personali di Cory Arcangel, Nathalie Djurberg
                        & Hans Berg, Rochelle Goldberg, Rashid Johnson, Ryan
                        McGinley e Pamela Rosenkranz.<br></br>È membro dell’IKT
                        – International Association of Curators of Contemporary
                        Art.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pannel">
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.saroliImage.childImageSharp.fluid}
                    alt="DONATELLA SAROLI"
                    className="img__pannel"
                  />
                  <h2>DONATELLA SAROLI</h2>
                  <p>
                    Nata e cresciuta a Roma, Donatella Saroli è ricercatrice e
                    producer. Le sue aree di ricerca sono la scrittura delle
                    donne, il femminismo e le arti visive, il ruolo della
                    ricerca nella pratica curatoriale, la produzione di video
                    art e film essays di artiste, feminist urbanism, le
                    relazioni fra istituzioni rilette attraverso il nuovo
                    materialismo. Dopo la Laurea in Letterature Straniere presso
                    l'Università La Sapienza, grazie alla Fulbright Fellowship,
                    si trasferisce negli Stati Uniti per un Ph.D alla Rutgers
                    University, dove approfondisce il pensiero femminista e i
                    feminist film studies.<br></br>Le sue esperienze
                    professionali includono l'insegnamento a Rudgers, la
                    redazione di articoli per quotidiani e riviste, la
                    traduzione e la produzione televisiva. A New York, per 17
                    anni, è stata coinvolta nel settore dei media come producer
                    e, in seguito, come responsabile per il Nord e Sud America
                    della produzione di programmi per le reti RAI e del centro
                    di ricerca sui media americani della RAI di NY. Nel 2012 si
                    trasferisce a Roma dove dal 2013 lavora presso l’ufficio del
                    Direttore Artistico del Museo MAXXI secolo e come
                    ricercatrice nell'ufficio curatoriale.
                  </p>
                </Col>
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.zuccalaImage.childImageSharp.fluid}
                    alt="L. Zuccala"
                    className="img__pannel"
                  />
                  <div className="">
                    <div>
                      <h2>LUCA ZUCCALA</h2>
                      <p>
                        Giornalista e critico, vicedirettore di ArtsLife, scrive
                        continuativamente di critica e mercato dell'arte per
                        Bell’Italia e Bell’Europa (Cairo) e Icon (Mondadori).
                        Direttore di State Of Milano, collabora con diverse
                        fiere d’arte e università italiane.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pannel__member" md={4}></Col>
              </Row>
              <Row className="winner"></Row>
              {/* DOCUMENTAZIONE */}
              <Row className="bando__parent">
                <Col md={8}>
                  <div className="bando__child">
                    <h1 className="section__header">DOCUMENTAZIONE</h1>
                    <p className="bando__text">
                      {" "}
                     Informazioni sul Bando Italian Art Trust 2021.{" "}
                    </p>
                    <div className="documentazione">
                      <p className="documentazione__header">TIMELINE</p>
                      <ul className="ol__main">
                        <li>
                          Apertura candidature <br></br>
                          <strong>21 dicembre 2020</strong>
                        </li>
                        <li>
                          Chiusura candidature <br></br>
                          <strong>10 febbraio 2021</strong>
                        </li>
                        <li>
                          Comunicazione vincitori da<strong> marzo 2021</strong>{" "}
                          <br></br>in funzione del numero delle candidature
                          ricevute
                        </li>
                        <li>
                          Termine per la produzione dell’opera vincitrice{" "}
                          <strong>entro 60 giorni</strong>{" "}
                          <br className="break__remove"></br>
                          dalla comunicazione dell’assegnazione del Premio di
                          produzione.<br></br>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bando__child">
                    <div className="documentazione">
                      <p className="documentazione__header">DOCUMENTAZIONE</p>
                      <ul className="ol__main">
                        <li>
                          <a
                            className="link__main"
                            href={IAT_oggettodelbando}
                            download="IAT_oggettodelbando"
                          >
                            Oggetto del bando
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_requisitidipartecipazione}
                            download="IAT_requisitidipartecipazione"
                          >
                            Requisiti di partecipazione
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_modalitapresentazionedomanda}
                            download="IAT_modalitapresentazionedomanda"
                          >
                            Modalità presentazione domanda
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_domandapartecipazione}
                            download="IAT_domandapartecipazione"
                          >
                            Allegato 1
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_propostaprogettuale}
                            download="IAT_propostaprogettuale"
                          >
                            Allegato 2
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_dichiarazioneoriginalitaefattibilita}
                            download="IAT_dichiarazioneoriginalitaefattibilita"
                          >
                            Allegato 3
                          </a>
                        </li>

                        <li>
                          <a
                            className="link__main"
                            href={IAT_regolamentoprivacy}
                            download="IAT_regolamentoprivacy"
                          >
                            Allegato 4
                          </a>
                        </li>
                        <li>
                          <a
                            className="link__main"
                            href={IAT_prospettoeconomico}
                            download="IAT_prospettoeconomico"
                          >
                            Allegato 5
                          </a>
                        </li>
                        <li className="extramargin">
                          <a
                            className="link__main"
                            href={IAT_cronoprogramma}
                            download="IAT_cronoprogramma"
                          >
                            Allegato 6
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={8}></Col>
                <Col md={4}></Col>
              </Row>

            </div>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="crowdfunding">
            <div className="section__wrap">
              <h1 className="section__header">CROWDFUNDING</h1>
              <Row>
                <Col md={12}>
                  <p>
                    A seguito del lancio del primo bando inauguriamo la campagna di crowdfunding con l’obiettivo di raccogliere risorse per finanziare giovani artisti e consentire la continuità del progetto. La campagna di raccolta fondi prevede il coinvolgimento di una serie di artisti attivi nel settore dell’arte contemporanea che contribuiranno all’iniziativa realizzando un progetto in digitale in tiratura limitata. Ogni sostenitore, a fronte di un'erogazione minima di 25 euro, riceverà come ricompensa il file di uno degli artisti coinvolti.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h3 className="crowd__header">
                    ARTISTI
                  </h3>
                </Col>
              </Row>
              <Row className="crowd__artist">
                <Col md={6} className="crowd__picture">
                  <Img
                    fluid={data.pozziImage.childImageSharp.fluid}
                    alt="LUCA POZZI"
                    className="crowd__img"
                  />
                  <p>
                    © Photo Credit: Cosimo Filippini
                  </p>
                </Col>
                <Col md={6} className="crowd__text">
                  <h4 className="crowd__name">
                    LUCA POZZI
                  </h4>
                Il primo artista a partecipare alla campagna crowdfunding di Italian Art Trust Onlus è Luca Pozzi con il progetto inedito "Dark Collection – Brera Map" (2019-2021).
                <br></br><br></br>
                Ogni sostenitore, a fronte di un’erogazione di 25 euro, riceverà  il file autenticato dall’artista contenente una mappa.
                <br></br><br></br>
                Grazie a un sofisticato sistema di Augmented Reality, utilizzabile esclusivamente mediante dispositivi Android, sarà possibile accedere attraverso il proprio cellulare e/o tablet a una mappa segreta che guiderà lo spettatore alla scoperta di una serie di sculture nascoste, oracoli contemporanei in bilico fra passato e presente, visibile e invisibile.
                <br></br><br></br>
                Per fruire dell’opera basterà inquadrare tramite fotocamera il QR code presente sull’opera digitale e scaricare un applicativo di realtà aumentata per Android chiamato Dark Collection Brera, visualizzare la mappa virtuale sul proprio dispositivo e recarsi nei luoghi segnalati per godere dell’esperienza artistica.

                <br></br><br></br>
                  <a href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/" target="blank">
                    <button
                      className="IAT__button__home"
                      variant="success"
                      href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/"
                    >
                      Vai al sito crowdfunding
                  </button>{" "}
                  </a>
                </Col>
              </Row>
              <Row className="crowd__artist">

                <Col md={6} className="crowd__text">
                  <h4 className="crowd__name">
                    ORNAGHI&PRESTINARI
                  </h4>
                  Il secondo progetto a partecipare alla campagna crowdfunding di Italian Art Trust Onlus è "GUIDA ALLA REALIZZAZIONE DEL NODO INTROVERSO" del duo Ornaghi&Prestinari.
                  <br></br><br></br>
                  Scaricando l'allegato si riceverà, infatti, una copia pdf dell'unica guida da collezione, redatta dal duo di artisti, per realizzare il nodo introverso (o nodo irrisolto). Tale manuale di istruzioni ne illustra tutti i passaggi passo dopo passo e, come nelle guide dei nodi marinari, ne spiega le caratteristiche e gli usi consigliati.

                  <br></br><br></br>
                  <a href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/" target="blank">
                    <button
                      className="IAT__button__home"
                      variant="success"
                      href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/"
                    >
                      Vai al sito crowdfunding
                  </button>{" "}
                  </a>
                </Col>
                <Col md={6} className="crowd__picture">
                  <Img
                    fluid={data.ornaghiprojectImage.childImageSharp.fluid}
                    alt="ORNAGHI&PRESTINARI"
                    className="crowd__img"
                  />
                  <p>
                    © Photo Credit: Giovanni De Angelis
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <p className="crowd__strong">

                    Partecipa anche tu alla nostra campagna crowdfunding e riceverai il progetto d’artista entro 8 giorni dalla data del tuo contributo!
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4, offset: 4 }} className="btn__container">
                  <a href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/" target="blank">
                    <button
                      className="IAT__button__home"
                      variant="success"
                      href="https://www.produzionidalbasso.com/project/italian-art-trust-sostieni-la-giovane-arte/"
                    >
                      SCOPRI DI PIÙ
                  </button>{" "}
                  </a>
                </Col>
              </Row>
            </div>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="partners">
            <div className="section__wrap">
              <h1 className="section__header">PARTNER</h1>
              <h4 className="partner__header">
                Italian Art Trust Onlus è un progetto sviluppato in
                collaborazione con:
              </h4>
              <Row className="partner">
                <Col className="pannel__member" md={2}>
                  <Img
                    fluid={data.loconteImage.childImageSharp.fluid}
                    alt="Loconte and Partners"
                    className="img__partner"
                  />
                </Col>
              </Row>
              <h4 className="partner__header">PARTNER TECNICO</h4>
              <Row className="partner">
                <Col className="pannel__member" md={4}>
                  <Img
                    fluid={data.generaliImage.childImageSharp.fluid}
                    alt="Generali logo"
                    className="img__partner"
                  />
                </Col>

                <Col className="pannel__member" md={3}>
                  <Img
                    fluid={data.artdefenderImage.childImageSharp.fluid}
                    alt="Art Defender"
                    className="img__partner"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Container>
        <div className="divider"></div>
        <Container>
          <section className="section" id="contatti">
            <div className="section__wrap">
              <h1 className="section__header">CONTATTI</h1>
              <p className="contatti__text">Per maggiori informazioni:</p>
              <p>
                <strong>
                  <a
                    href="mailto:italianart@leptrustee.it"
                    className="contatti__email"
                  >
                    italianart@leptrustee.it
                  </a>
                </strong>
              </p>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
  query {
    montiImage: file(relativePath: { eq: "EMonti.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    saroliImage: file(relativePath: { eq: "D. Saroli.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    botreImage: file(relativePath: { eq: "F.Botre.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonettiImage: file(relativePath: { eq: "G.Bonetti.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zuccalaImage: file(relativePath: { eq: "L.Zuccala.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bergaminiImage: file(relativePath: { eq: "M.Bergamini.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    poggialiImage: file(relativePath: { eq: "M.Poggiali.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    anselmiImage: file(relativePath: { eq: "R.Anselmi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    farinottiImage: file(relativePath: { eq: "R.Farinotti.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    raimondiImage: file(relativePath: { eq: "S.Raimondi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImage: file(relativePath: { eq: "LOGO_Onlus.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    loconteImage: file(relativePath: { eq: "loconte.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    generaliImage: file(relativePath: { eq: "BancaGeneraliPrivate.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artdefenderImage: file(relativePath: { eq: "artdefender.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lecciImage: file(relativePath: { eq: "F.Lecci.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winner2021Image: file(relativePath: { eq: "2021winner.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winner2021projectImage: file(relativePath: { eq: "2021project.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pozziImage: file(relativePath: { eq: "LPozzi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ornaghiprojectImage: file(relativePath: { eq: "Ornaghi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
